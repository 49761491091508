    /* Styles for the video uploader container */
    .video-uploader {
      display: inline-block;
      position: relative;
      overflow: hidden;
      border: 2px dashed #ccc;
      border-radius: 5px;
      padding: 20px;
      text-align: center;
      cursor: pointer;
    }

    /* Styles for the file input element (hidden) */
    .file-input {
      display: none;
    }

    /* Styles for the file label */
    .file-label {
      display: block;
      font-size: 16px;
      color: #555;
      cursor: pointer;
    }

    /* Styles for the file icon */
    .file-label i {
      margin-right: 5px;
    }

    /* Styles for the file name display */
    .file-name {
      margin-top: 10px;
      font-size: 14px;
      color: #999;
    }

    /* Styles for the file uploader on hover */
    .video-uploader:hover {
      background-color: #f7f7f7;
    }

    /* Styles for the file uploader on file selection */
    .video-uploader .file-input:focus + .file-label {
      outline: 2px solid #3498db;
    }

    .file-input-container {
      display: flex;
      align-items: center;
/*      // border: 1px solid #ccc;*/
      padding: 10px;
/*      // border-radius: 5px;*/
      margin-bottom: 10px;
    }

    .file-input-container label {
      margin-right: 10px;
    }