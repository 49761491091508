* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.nav__div {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	width: 100vw;
	padding: 0 !important;
	overflow-x: hidden;
	background-color: black;
	color: white;
}

.nav__logo {
	width: 50px;
	height: 50px;
}

.nav__div2 {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 1300px;
}

.nav__link {
	text-decoration: none;
	color: white;
	cursor: pointer;
	transition: transform 0.3s ease-in;
}

.nav__link:hover {
	transform: scale(1.1);
	color: white;
}